import { createApp, reactive, ref } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import { createPinia } from 'pinia'

// 解决报错：loop completed with undelivered notifications.
const debounce = (callback, delay) => {
    let tid
    return function (...args) {
        const ctx = self
        tid && clearTimeout(tid)
        tid = setTimeout(() => {
            callback.apply(ctx, args)
        }, delay)
    }
}

const _ = window.ResizeObserver
window.ResizeObserver = class ResizeObserver extends _ {
    constructor(callback) {
        callback = debounce(callback, 20)
        super(callback)
    }
}

const app=createApp(App);
app.config.globalProperties.$classId=reactive({title:'',id:'',subTitle:'',zd:''});
app.use(store).use(createPinia()).use(ElementPlus).use(router).mount("#app");
