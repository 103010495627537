import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: ()=> import("@/components/index.vue"),
    redirect:'/home',
    children:[
      {
        path:'/home',
        name:'首页',
        component:()=> import('@/components/news')
      },
      {
        path:'/shopping',
        name:'商品列表',
        component:()=> import('@/components/shopping')
      },
      {
        path:'/system',
        name:'权限管理',
        component:()=> import('@/components/system')
      },
      {
        path:'/manufacturer',
        name:'厂家管理',
        component:()=> import('@/components/manufacturer')
      },
      {
        path:'/newsList',
        name:'新闻列表',
        component:()=> import('@/components/news/list.vue')
      },
    ]
  },
  {
    path:'/register',
    name:'注册',
    component:()=>import('@/components/register.vue')
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
