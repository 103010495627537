<template>
  <router-view />
</template>

<style lang="scss">
@import "./assets/scss/style.scss";

/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 10px;
  height: 5px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(61, 59, 59, 0.4);
}
</style>
